import {useEffect} from "react"
import {useMap} from "react-leaflet"
import Locate from "leaflet.locatecontrol"
import "leaflet.locatecontrol/dist/L.Control.Locate.min.css"

const AddLocateLogic = ({coords, data, geoJsonKey}) => {

    // Access the map context with the useMap hook
    const map = useMap()

    // Add locate control once the map loads
    useEffect(() => {
        const locateOptions = {
            position: "topleft",
            initialZoomLevel: 10,
            showPopup: true,
        }
        const locateControl = new Locate(locateOptions)
        locateControl.addTo(map)
    }, [map])

    return null
}

export default AddLocateLogic