import React, { useEffect, useRef, useState } from 'react'
import { MapContainer, TileLayer, FeatureGroup, GeoJSON, MapConsumer } from 'react-leaflet'
import AddLocate from './add-locate'
import MarkerClusterGroup from 'react-leaflet-markercluster';
import './map.css'
import PositionScrubber from './PositionScrubber';
import { makeRandomKey, createPopups, createClusters } from './helper-functions'
import jsonJobs from '../../jobs.json'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import GetVisibleMapMarkers from './GetVisibleMarkers';

// Parse the data from the jobs.xml file.
const originalData = PositionScrubber( jsonJobs );

const lang = process.env.LANG;
const isFrench = lang === 'FR';
const isLocal = ['dev', 'development'].includes( process.env.NODE_ENV );

const distance = ( lat1, lon1, lat2, lon2, unit ) => {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin( Δφ / 2 ) * Math.sin( Δφ / 2 ) + Math.cos( φ1 ) * Math.cos( φ2 ) * Math.sin( Δλ / 2 ) * Math.sin( Δλ / 2 );
    const c = 2 * Math.atan2( Math.sqrt( a ), Math.sqrt( 1 - a ) );
    const d = R * c; // in metres
    return d / 1000
}

const JobMap = () => {

    /*      STATES      */
    //  Track which markers are being actively displayed on the map
    const [displayedMarkers, setDisplayedMarkers] = useState()

    // User Geo Location Coordinates
    const [coords, setCoords] = useState( [null, null] );

    //  GeoJson Key to handle updating geojson inside react-leaflet
    const [geoJsonKey, setGeoJsonKey] = useState( 'initialKey123abc' )

    // Track which markers are visible on the map.
    const [visibleMarkers, setVisibleMarkers] = useState( originalData )

    const [visibleJobs, setVisibleJobs] = useState( [] );
    const [shiftType, setShiftType] = useState( '' );
    const [jobStatus, setJobStatus] = useState( '' );

    useEffect( () => {
        let tempList = [];
        visibleMarkers.forEach( ( marker ) => (marker.properties.jobs.forEach( ( job ) => {
            tempList.push( job );
        } )) );
        setVisibleJobs( tempList );
    }, [visibleMarkers] )


    useEffect( () => {
        function getClosestPoint( jobData ) {

            if (!jobData || !coords[0] || !coords[1]) return;

            for (let job in jobData) {
                if (jobData.hasOwnProperty( job )) {
                    jobData[job]['distance'] = distance( parseFloat( jobData[job].geometry.coordinates[1] ), parseFloat( jobData[job].geometry.coordinates[0] ), coords[0], coords[1], 'K' )
                }
            }
            jobData.sort( function ( a, b ) {
                let distA = a.distance
                let distB = b.distance
                if (distA < distB) {
                    return -1;
                } else if (distA > distB) {
                    return 1;
                }
                return 0;
            } );
            if (!jobData[0] || !jobData[1]) return;
            console.log( 'Job Data: ', jobData[0], jobData[1] )
            let numJobs1 = document.getElementById( 'num_jobs_1' );
            let numJobs2 = document.getElementById( 'num_jobs_2' );
            let jobLocation1 = document.getElementById( 'job_location_1' );
            let jobLocation2 = document.getElementById( 'job_location_2' );
            let location1Href = document.getElementById( 'location_1_href' );
            let location2Href = document.getElementById( 'location_2_href' );


            location1Href.href = `/search/?term=${jobData[0].properties.address}`;
            location2Href.href = `/search/?term=${jobData[1].properties.address}`;


            if (jobData[0].properties.hasOwnProperty( 'address' )) {
                jobLocation1.innerText = jobData[0].properties.address;
            }
            if (jobData[1].properties.hasOwnProperty( 'address' )) {
                jobLocation2.innerText = jobData[1].properties.address;
            }
            let totalJobs1 = jobData[0].properties.jobs.length;
            if (totalJobs1 > 1) {
                numJobs1.innerText = totalJobs1 + ' Openings';
            } else if (totalJobs1 === 1) {
                numJobs1.innerText = '1 Opening';
            }
            let totalJobs2 = jobData[1].properties.jobs.length;
            if (totalJobs2 > 1) {
                numJobs2.innerText = totalJobs2 + ' Openings';
            } else if (totalJobs1 === 1) {
                numJobs2.innerText = '1 Opening';
            }

        }

        getClosestPoint( displayedMarkers );
    }, [displayedMarkers, coords] )

    const tableHeadCells = [{
        id: 'headline', width: 629, label: isFrench ? 'Titre du poste' : 'Position Title'
    }, {
        id: 'country', width: 353, label: isFrench ? 'Pays' : 'Country'
    }, {
        id: 'city', width: 310, label: isFrench ? 'Ville' : 'City'
    }, {
        id: isFrench ? 'positionFunctionFR' : 'positionFunction',
        width: 310,
        label: isFrench ? 'Fonction du poste' : 'Job Function'
    }, {
        id: 'listDate', width: 310, label: isFrench ? 'Date de publication' : 'Posting Date'
    }, {
        id: isFrench ? 'closeDateFR' : 'closeDate', width: 310, label: isFrench ? 'Date de clôture' : 'Closing Date'
    }]

    const defaultOrderDirection = 'asc';
    const invertedDirection = {
        asc: 'desc', desc: 'asc'
    }

    const [orderBy, setOrderBy] = useState( tableHeadCells[4].id );
    const [orderDirection, setOrderDirection] = useState( invertedDirection[defaultOrderDirection] );
    const [rowsPerPage] = useState( 10 );
    const [page, setPage] = useState( 0 );

    /*      REFS      */
    const groupRef = useRef();
    const clusterRef = useRef();

    function descendingComparator( a, b, orderBy ) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator( order, orderBy ) {
        return order === 'desc' ? ( a, b ) => descendingComparator( a, b, orderBy ) : ( a, b ) => -descendingComparator( a, b, orderBy );
    }

    const handleChangePage = ( event ) => {
        let value = event.currentTarget.getAttribute( 'value' );
        if (value === 'next') {
            let addition = rowsPerPage === 1 ? 1 : 0;
            if (page + (1 + addition) > visibleJobs.length / rowsPerPage) {
                return;
            }
            setPage( page + 1 );
        } else {
            setPage( parseInt( value ) );

        }
    };
    // Handler for table sorting.
    const handleSortChange = ( event ) => {
        let elementTarget = null;
        if (tableHeadCells.some( e => e.id === event.target.id )) {
            elementTarget = 'target';
        } else if (tableHeadCells.some( e => e.id === event.currentTarget.id )) {
            elementTarget = 'currentTarget';
        }
        if (event[elementTarget].id === orderBy) {
            event[elementTarget].setAttribute( 'aria-sort', event[elementTarget].getAttribute( 'aria-sort' ) === 'descending' ? 'ascending' : 'descending' );
            setOrderDirection( orderDirection === 'desc' ? 'asc' : 'desc' );
        } else if (elementTarget) {
            event[elementTarget].setAttribute( 'aria-sort', defaultOrderDirection )
            setOrderDirection( defaultOrderDirection );
            setOrderBy( event[elementTarget].id );
        }
    }
    let map = null;


    /*      USE EFFECTS     */
    useEffect( () => {
        const newKey = makeRandomKey( 10 );
        setGeoJsonKey( newKey )
    }, [displayedMarkers] )

    useEffect( () => {
        setDisplayedMarkers( originalData );
        const newKey = makeRandomKey( 10 );
        setGeoJsonKey( newKey )

        // Get the users Location from the browser. Store the coordinates
        function getLocation() {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition( function setCoordinates( position ) {
                    const lat = position.coords.latitude;
                    const long = position.coords.longitude
                    setCoords( [lat, long] );
                    // setCoords([43.700287227518146, -79.30802691936593]) // Toronto
                } );
            }
        }

        getLocation();

    }, [] );
    useEffect( () => {
        setPage( 0 );
    }, [visibleJobs] )

    const handleShiftTypeChange = ( event ) => {
        let data = originalData;
        let type = event.target.value;

        if (data.length > 0) {
            if (type === 'any') {
                setDisplayedMarkers( PositionScrubber( jsonJobs ) )
            } else {

                let filteredMarkers = [];

                // Loop each marker
                for (let marker in data) {
                    if (data.hasOwnProperty( marker )) {
                        if (data[marker].hasOwnProperty( 'properties' )) {
                            if (data[marker].properties.hasOwnProperty( 'jobs' )) {
                                // Grab all the jobs on that marker
                                let locationJobs = data[marker].properties.jobs;
                                // console.log("Jobs: ", locationJobs)
                                // Remove all jobs who arent the selected type.
                                // let tempJobs = jobs.filter((job) => job.type === type);
                                let tempJobs = [];
                                for (let j in locationJobs) {
                                    if (locationJobs.hasOwnProperty( j )) {
                                        console.log( 'Shift: ', locationJobs[j], type )
                                        if (locationJobs[j].shift === type) {
                                            tempJobs.push( locationJobs[j] )
                                        } else {
                                            // console.log("Removing: ", locationJobs[j]);
                                        }
                                        // console.log("Location: ", locationJobs[j])
                                    }
                                }
                                // If one or more jobs are the selected type, add this marker with those jobs
                                // console.log("Temp Jobs: ", tempJobs)
                                if (tempJobs && tempJobs.length > 0) {
                                    const tempMarker = data[marker];
                                    tempMarker['properties']['jobs'] = tempJobs;
                                    // console.log("Temp marker: ", tempMarker)
                                    filteredMarkers.push( tempMarker );
                                }
                            }
                        }
                    }
                }
                setDisplayedMarkers( filteredMarkers )
            }
        }
        setShiftType( type );

    }
    /*      RETURN      */
    if (typeof window !== 'undefined') {
        return (
          <>
            <section className={"pt6 pt5-lg pb5 section-job-search"}>
              {/*Form*/}
              <form
                action="/search/"
                className={"r jc-lg ac fww search-form w100"}
                style={{
                  maxWidth: 800,
                  gap: "12px",
                  justifyContent: "space-between",
                }}
              >
                {/*H2 Title*/}
                <h2
                  style={{
                    whiteSpace: "nowrap",
                    color: "#333333",
                    fontSize: "40px",
                    height: "53px",
                  }}
                  className={"m0 r jc ac"}
                >
                  Explore Careers
                </h2>
                {/*Search*/}
                <TextField
                  className={"w100-md formField"}
                  id="search_field_search"
                  name="term"
                  style={{ width: 300 }}
                  label="Search: Career Title, Location, etc.."
                  variant="outlined"
                />

                {/*<div className={"r afe"}>*/}
                {/*Job Type*/}
                {/*<FormControl className={"mr2 mr0-md w100-md mt2 mt3-md formField"}*/}
                {/*             style={{width: 200, marginRight: "0.5rem"}}>*/}
                {/*    <InputLabel>Shift Type</InputLabel>*/}
                {/*    <Select*/}
                {/*        labelId="search-field-province-category"*/}
                {/*        id="search_field_category"*/}
                {/*        value={shiftType}*/}
                {/*        label="Shift Type"*/}
                {/*        name={"type"}*/}
                {/*        onChange={handleShiftTypeChange}*/}
                {/*    >*/}
                {/*        <MenuItem value={"any"}>{"Any Shift Type"}</MenuItem>*/}
                {/*        <MenuItem value={"Day Shift"}>{"Day Shift"}</MenuItem>*/}
                {/*        <MenuItem value={"Night Shift"}>{"Night Shifts"}</MenuItem>*/}
                {/*        <MenuItem value={"Weekends"}>{"Weekends"}</MenuItem>*/}
                {/*        <MenuItem value={"Delivery"}>{"Delivery"}</MenuItem>*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                {/*Job Status*/}
                {/*<FormControl className={"mr2 mr0-md w100-md mt2 mt3-md formField"}*/}
                {/*             style={{marginRight: "0.5rem", width: 150}}>*/}
                {/*    <InputLabel*/}
                {/*        style={{paddingLeft: "8px"}}>{"Job Status"}</InputLabel>*/}
                {/*    <Select*/}

                {/*        labelId="search-field-type-label"*/}
                {/*        id="search_field_type"*/}
                {/*        value={jobStatus}*/}
                {/*        label={"Job Status"}*/}
                {/*        style={{height: "53px"}}*/}
                {/*        onChange={handleShiftTypeChange}*/}
                {/*    >*/}
                {/*        <MenuItem value={"Any Job Type"}>{"Any Job Type"}</MenuItem>*/}
                {/*        <MenuItem value={"full"}>{"Full Time"}</MenuItem>*/}
                {/*        <MenuItem value={"part"}>{"Part Time"}</MenuItem>*/}
                {/*    </Select>*/}
                {/*</FormControl>*/}
                {/*Submit Button*/}
                <div className={"c w100-md formField"}>
                  <button
                    className={"button m0"}
                    style={{
                      height: 54,
                      borderRadius: "5px",
                      marginTop: "0px",
                    }}
                    type={"submit"}
                  >
                    Search Careers
                  </button>
                </div>
                {/*</div>*/}
              </form>
            </section>
            <section>
              {/*MAP*/}
              <MapContainer
                maxBounds={[
                  [39, -70],
                  [44, -72],
                ]}
                tap={false}
                style={{
                  height: "500px",
                  maxWidth: "800px",
                  margin: "auto",
                }}
                className="map-container"
                center={[42.3, -71.31464899001426]}
                zoom={8}
                minZoom={6}
                // maxZoom={13}
                scrollWheelZoom={true}
                attributionControl={false}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url={`https://api.maptiler.com/maps/${
                    isFrench
                      ? "7ba68c18-9105-41e5-9e76-18e836d16568"
                      : "cd90233e-a380-4cc9-bc28-8fb0d1a5a6b2"
                  }/{z}/{x}/{y}.png?key=${
                    isLocal ? "yYbMBbxgyqxOmY9EPOTS" : "XHwjzhWLVRvCaw3yIvcU"
                  }`}
                />
                <FeatureGroup ref={groupRef} name="Jobs">
                  <MarkerClusterGroup
                    eventHandlers={{
                      click: (e) => {
                        if (map.getZoom() < 13) {
                          map.setView([e.latlng.lat + 0.035, e.latlng.lng], 13);
                        }
                      },
                    }}
                    ref={clusterRef}
                    key={geoJsonKey}
                    iconCreateFunction={createClusters}
                  >
                    <MapConsumer>
                      {(m) => {
                        map = m;
                        return null;
                      }}
                    </MapConsumer>
                    <GeoJSON
                      key={geoJsonKey}
                      onEachFeature={createPopups}
                      data={displayedMarkers}
                    />
                  </MarkerClusterGroup>
                </FeatureGroup>
                <GetVisibleMapMarkers
                  groupRef={groupRef}
                  clusterRef={clusterRef}
                />
                <AddLocate geoJsonKey={geoJsonKey} data={displayedMarkers} />
              </MapContainer>
            </section>
          </>
        );
    }
    return null;
}

export default JobMap







