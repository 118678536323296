import locations from "../components/locations.json"

function replaceAll(haystack, needle, replacement) {
    while (haystack.indexOf(needle) >= 0) {
        haystack = haystack.replace(needle, replacement);
    }
    return haystack
}

const removeAccents = (str) => {
    const accents = {
        a: 'àáâãäåæ', c: 'ç', e: 'èéêëæ', i: 'ìíîï', n: 'ñ', o: 'òóôõöø', s: 'ß', u: 'ùúûü', y: 'ÿ'
    }
    let newStr = "";
    for (let char in str) {
        let continueFlag = false;
        for (let accent in accents) {
            if (accents[accent].includes(str[char])) {
                newStr += accent;
                continueFlag = true;
                break;
            }
        }
        if (continueFlag) continue;
        newStr += str[char];
    }
    return newStr;
};

const PositionScrubber = (jobs) => {

    let geoJson = [];


    // If there are jobs
    if (jobs) {

        // Loop thru each Job
        for (let job in jobs) {

            // let skip = false;

            if (jobs.hasOwnProperty(job)) {
                let url, path, locale = "";
                if (jobs[job].hasOwnProperty('applyUrl')) {
                    url = new URL(jobs[job]['applyUrl'].toString())
                    path = url.pathname;
                    locale = url.searchParams.get("locale");
                }


                let headline;
                if (jobs[job].hasOwnProperty('title')) {
                    headline = jobs[job]['title'].toString();
                }
                let storeIdentifier;
                if (jobs[job].hasOwnProperty('Storenumber')) {
                    storeIdentifier = jobs[job]['Storenumber'].toString();
                }
                console.log("storeIdentifier: ", storeIdentifier)
                let jobCategory;
                if (jobs[job].hasOwnProperty('jobcategory')) {
                    jobCategory = jobs[job]['jobcategory'].toString();
                }
                 let shiftType;
                if (jobs[job].hasOwnProperty('shifttype')) {
                    shiftType = jobs[job]['shifttype'].toString();
                }
                let address;
                if (jobs[job].hasOwnProperty('address')) {
                    address = jobs[job]['address'].toString();
                }
                let status;
                if (jobs[job].hasOwnProperty('status')) {
                    status = jobs[job]['status'].toString();
                }
                let careerText = "careers";
                let langSlug = "/en/";
                let posPath = langSlug + careerText + "/" + headline + "-" + storeIdentifier + "-" + "az" + "-" + path.split("-")[0].replace("/processes/", "") + "-" + locale;
                posPath = posPath.toLowerCase();
                posPath = replaceAll(posPath, "-/ ", "-");
                posPath = replaceAll(posPath, "-/ ", "-");
                posPath = replaceAll(posPath, "   ", "-");
                posPath = replaceAll(posPath, "  ", "-");
                posPath = replaceAll(posPath, " ", "-");
                posPath = replaceAll(posPath, ", ", "-");
                posPath = replaceAll(posPath, ",", "");
                posPath = replaceAll(posPath, "(", "");
                posPath = replaceAll(posPath, "(", "");
                posPath = replaceAll(posPath, ")", "");
                posPath = replaceAll(posPath, "---", "-");
                posPath = replaceAll(posPath, "--", "-");
                posPath = replaceAll(posPath, "-/-", "-");
                posPath = replaceAll(posPath, "-&-", "-");
                posPath = removeAccents(posPath);

                let listDate;
                if (jobs[job].hasOwnProperty('listDate')) {
                    listDate = jobs[job]['listDate'].toString();
                    listDate = new Date(listDate).getTime();
                }

                if (storeIdentifier !== null) {
                    if (locale !== undefined) {

                        let head = "error";
                        if (headline !== null) {
                            head = headline;
                        }
                        let obj = geoJson.find(job => job.properties.storeIdentifier === storeIdentifier);
                        let locationDetails = locations.find(sto => sto['storeIdentifier'].toString() === storeIdentifier);
                        if (locationDetails !== undefined) {
                            if (obj !== undefined) {
                                if (obj.hasOwnProperty('properties')) {
                                    // for (let i = 0; i < 9; i++) {
                                    if (obj.properties.hasOwnProperty('jobs')) {
                                        obj.properties.jobs.push({
                                            "language": locale,
                                            "url": url.href,
                                            "path": path,
                                            "jobCategory": jobCategory,
                                            "shiftType": shiftType,
                                            "status": status,
                                            "address": address,
                                            "headline": head,
                                            "listDate": listDate,
                                            "descUrl": posPath
                                        })
                                    }
                                }
                            } else {
                                geoJson.push({
                                    "type": "Feature", "geometry": {
                                        "type": "Point",
                                        "coordinates": [locationDetails.latitude, locationDetails.longitude]
                                    }, "properties": {
                                        "jobs": [{
                                            "language": locale,
                                            "url": url.href,
                                            "path": path,
                                            "jobCategory": jobCategory,
                                            "shiftType": shiftType,
                                            "status": status,
                                            "address": address,
                                            "headline": head,
                                            "listDate": listDate,
                                            "descUrl": posPath
                                        }],
                                        "storeIdentifier": storeIdentifier,
                                        "address": locationDetails['address']

                                    }
                                },)

                            }
                        }
                    }
                }
            }
        }
        return geoJson;
    }
}

export default PositionScrubber;
