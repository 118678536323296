import {useMap, useMapEvents} from "react-leaflet"
import L from "leaflet"

const GetVisibleMapMarkers = ({groupRef, clusterRef, setVisibleMarkers}) => {
    // Access the map context with the useMap hook
    const map = useMap()
    let lastZoom = map.getZoom();
    // Get visible markers on the map
    const getVisibleMarkers = () => {
        let popupPane = document.getElementsByClassName("leaflet-popup-pane");
        if (popupPane.length > 0) {
            let zoom = map.getZoom()
            if (zoom < lastZoom) {
                map.closePopup();
            }
        }
        lastZoom = map.getZoom();
    }

    useMapEvents({
        zoomend: () => getVisibleMarkers(), moveend: () => getVisibleMarkers(),
    })

    return null;

}

export default GetVisibleMapMarkers
