import * as React from "react"
import Header from "../components/Header/Header";
import "./../sass/main.scss"
import Footer from "../components/Footer/Footer";
import JobMap from "../components/JobMap";

import SwiperCore, {Navigation, Pagination, A11y, Autoplay} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';

import honeyDewLogo from "../images/HoneyDew-logo.png";
import subwayLogo from "../images/Subway-logo.png";
import shellLogo from "../images/Shell-logo.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import imgJoinTeam from "../images/Join.png";
import VideoCarousel from "../components/VideoCarousel/VideoCarousel";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const indexPage = () => {
  return (
    <>
      <Header
        title={"Map | East Side Enterprises"}
        description={"Map | East Side Enterprises"}
        lang={"EN"}
        langLink={"/"}
        bodyClasses={"page-en"}
      />
      <main style={{ backgroundColor: "#ffffff" }} id={"main"}>
        <section>
          <div className={"container r-c-lg jc ac"}>
            <div className="w66 w100-lg c ac-lg jc banner-container">
              <img
                src={imgJoinTeam}
                className={"join-team-img"}
                alt={"Join Team"}
                width={190}
                height={190}
              />
              <Swiper
                modules={[Navigation, Pagination, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                navigation
                loop={true}
                autoplay={{ delay: 5000 }}
                pagination={{ clickable: true }}
                style={{ height: "100%", width: "100%" }}
              >
                <SwiperSlide>
                  <div className={"swipe-slide slide-1-img"} />
                </SwiperSlide>
                <SwiperSlide>
                  <div className={"swipe-slide slide-2-img"} />
                </SwiperSlide>
                <SwiperSlide>
                  <div className={"swipe-slide slide-3-img"} />
                </SwiperSlide>
                <SwiperSlide>
                  <div className={"swipe-slide slide-4-img"} />
                </SwiperSlide>
                <SwiperSlide>
                  <div className={"swipe-slide slide-5-img"} />
                </SwiperSlide>
                <SwiperSlide>
                  <div className={"swipe-slide slide-6-img"} />
                </SwiperSlide>
                <SwiperSlide>
                  <div className={"swipe-slide slide-7-img"} />
                </SwiperSlide>
                <SwiperSlide>
                  <div className={"swipe-slide slide-8-img"} />
                </SwiperSlide>
                <SwiperSlide>
                  <div className={"swipe-slide slide-9-img"} />
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="w33 w100-lg h100-lg c jc ac jobs-near-you-container">
              <h1 className={"cw mb3 mt3"}>Careers Near you</h1>
              <a
                id="location_1_href"
                href="/"
                style={{ textDecoration: "none" }}
                className={"c mx3 py2 w80 my2 jc ac w100 jobs-near-you-box"}
              >
                <p id="num_jobs_1" className={"my1"}>
                  ...
                </p>
                <p id="job_location_1" className={"my1"}>
                  ...
                </p>
              </a>
              <a
                id="location_2_href"
                href="/"
                style={{ textDecoration: "none" }}
                className={"c mb4 mx3 py2 w80 mt2 jc ac w100 jobs-near-you-box"}
              >
                <p id="num_jobs_2" className={"my1"}>
                  ...
                </p>
                <p id="job_location_2" className={"my1"}>
                  ...
                </p>
              </a>
              <p className={"pb4  more-jobs-in-your-area"}>
                <a href={"/search"}>View More Careers</a> in Your Area
              </p>
            </div>
          </div>
        </section>
        <section style={{ backgroundColor: "#F1FBFD" }}>
          <div className={"container"}>
            <JobMap />
            <div className="home-logos-container">
              <img
                width={78 * 1.2}
                height={72 * 1.2}
                src={shellLogo}
                alt="Shell Logo"
              />
              <img
                width={100 * 1.4}
                height={50 * 1.4}
                src={honeyDewLogo}
                alt="HoneyDew Logo"
              />
              <img
                width={100 * 1.5}
                height={29 * 1.5}
                src={subwayLogo}
                alt="Subway Logo"
              />
            </div>
          </div>
        </section>
        <VideoCarousel />
      </main>
      <Footer />
    </>
  );
};

export default indexPage;
