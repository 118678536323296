import React from "react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

SwiperCore.use([Pagination, Navigation]);

export default function VideoCarousel() {
  return (
    <section style={{ padding: "48px 0px 52px" }}>
      <Swiper
        spaceBetween={200}
        slidesPerView={1}
        loop={true}
        pagination={{ clickable: true }}
        navigation
      >
        <SwiperSlide>
          <div style={{ display: "flex", margin: "0px 48px 40px 48px" }}>
            <iframe
              style={{ margin: "24px auto" }}
              width={800}
              height={506}
              className="carousel-video"
              src="https://www.youtube.com/embed/gKmhooAxMCU?si=ZYeuP_ZyPxqz1Gm2"
            ></iframe>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ display: "flex", margin: "0px 48px 40px 48px" }}>
            <iframe
              style={{ margin: "24px auto" }}
              width={800}
              height={506}
              className="carousel-video"
              src="https://www.youtube.com/embed/TjJXgVvgl_M?si=-iJyU3CCUSMPraIy"
            ></iframe>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div style={{ display: "flex", margin: "0px 48px 40px 48px" }}>
            <iframe
              style={{ margin: "24px auto" }}
              width={800}
              height={506}
              className="carousel-video"
              src="https://www.youtube.com/embed/GiVHe_4GCYE?si=byTi53XmFMoRc_GR"
            ></iframe>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
}
